
<script setup>
    import { ref, watch, computed } from "vue";

    const props = defineProps({
        modelValue: { type: String, required: true , },
        filter    : { type: Object, required: false, },
    });

    const emit = defineEmits(["update:modelValue", "search"]);

    const model = computed({
        get() { return props.modelValue },
        set(val) { emit("update:modelValue", val) },
    });


    /* emit throttled search */

    let timeout = null;

    watch(model, () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            emit("search", model.value);
        }, 400);
    });
</script>

<template>
    <div style="position: relative;">
        <input v-model="model" class="form-control search-input" :placeholder="$t('Search')" v-bind="$attrs" style="padding-right: 3rem;"/>

        <!-- left icon -->
        <div class="d-flex justify-content-center align-items-center search-icon" style="position: absolute; top: 0; left: 0.25rem; bottom: 0; pointer-events: none;">
            <div class="d-flex justify-content-center align-items-center" style="height: 100%; aspect-ratio: 1;">
                <!--
                <i class="far fa-search" style="font-size: 1.25rem;"></i>
                -->
                <i class="bi bi-search text-muted" style="font-size: 1.25em;" />
            </div>
        </div>

        <!-- right icons -->
        <template v-if="filter">
            <div class="d-flex justify-content-center align-items-center" style="position: absolute; top: 0; right: 0.25rem; bottom: 0;">
                <button class="btn d-flex justify-content-center align-items-center" style="height: 100%; aspect-ratio: 1; margin-right: -0.5rem;" @click="filter.showOrderSheet = !filter.showOrderSheet">
                    <i class="far fa-sort-alt" style="font-size: 1.25em;"></i>
                </button>
                <button class="btn d-flex justify-content-center align-items-center" style="height: 100%; aspect-ratio: 1;" @click="filter.showFieldSheet = !filter.showFieldSheet">
                    <i class="far fa-sliders-h" style="font-size: 1.25em;"></i>
                </button>
            </div>
        </template>

        <template v-else>
            <div class="d-flex justify-content-center align-items-center" style="position: absolute; top: 0; right: 0.25rem; bottom: 0;">
                <template v-if="model">
                    <button class="btn d-flex justify-content-center align-items-center" @click="emit('update:modelValue', '')">
                        <i class="bi bi-x-lg" style="font-size: 1.25em;"></i>
                    </button>
                </template>
            </div>
        </template>
    </div>
</template>

<style scoped>
    .search-input {
        transition: all 400ms ease-in-out;

        height: 2.75rem;
        padding-left: 2.75rem;
        border-radius: 999px;

        /* 
        &:focus {
            padding-left: 0.75rem;
        }
        */
    }

    /*
    .search-icon {
        transition: all 400ms ease-in-out;
    }

    .search-input:focus ~ .search-icon {
        margin-left: -1rem;
        opacity: 0%;
    }


    .search-input::placeholder {
        color: rgb(60%, 60%, 60%);
    }
    */
</style>
